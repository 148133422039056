<template>
  <div>
    <v-row no-gutters>
      <v-col cols="12">
        <v-sheet
          width="100%"
          class="py-12 px-8"
        >
          <v-row no-gutters>
            <v-col
              xs="0"
              sm="0"
              md="0"
              lg="1"
              xl="1"
            >
            </v-col>

            <v-col
              xs="12"
              sm="12"
              md="12"
              lg="10"
              xl="10"
              class="d-flex flex-column"
            >
              <v-card outlined>
                <v-card-title class="d-flex">
                  <span class="font-weight-bold">
                    Exposição USD
                  </span>

                  <v-spacer></v-spacer>
  
                  <v-btn
                    elevation="0"
                    tile
                    color="primary"
                    :disabled="!isExposicaoCambialModified"
                    @click="saveChangesExposicaoCambial"
                  >
                    Save Changes
                  </v-btn>
                </v-card-title>

                <div style="margin-left: 70px; margin-right: 70px">
                  <v-simple-table class="no-hover">
                    <tbody>
                      <tr v-for="(item, rowIndex) in itemsExposicaoCambialState" :key="rowIndex" 
                          :class="{'exclusive-cell': rowIndex === 3}">
                        <td :class="{'font-weight-black': rowIndex >= 3}" style="width: 230px;">
                          {{ item.name }}
                        </td>
                        
                        <td
                          v-for="(value, colIndex) in item.values"
                          :key="colIndex"
                          :class="{
                            'font-weight-black': rowIndex === 0,
                          }"
                          class="text-center"
                        >
                          <v-text-field
                            v-if="[1, 2].includes(rowIndex)"
                            v-model="item.values[colIndex]"
                            :disabled="false"
                            dense
                            class="mb-n4 centered-input"
                            style="text-align: center;"
                            @input="updateItemsExposicaoCambialState(colIndex, itemsExposicaoCambialState[colIndex].values)"
                          >
                          </v-text-field>
                          
                          <template v-else>
                            {{ value }}
                          </template>
                        </td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </div>
              </v-card>

              <v-card outlined class="mt-10">
                <v-card-title class="d-flex">
                  <span class="font-weight-bold">
                    Hedging USD
                  </span>

                  <v-spacer></v-spacer>
  
                  <v-btn
                    elevation="0"
                    tile
                    color="primary"
                    :disabled="!isHedgingModified"
                    @click="saveChangesHedging"
                  >
                    Save Changes
                  </v-btn>
                </v-card-title>

                <div style="margin-left: 70px; margin-right: 70px">
                  <v-simple-table class="no-hover">
                    <tbody>
                      <tr v-for="(item, rowIndex) in itemsHedgingState" :key="rowIndex" 
                          :class="{'exclusive-cell': rowIndex === 4}">
                        <td :class="{'font-weight-bold': rowIndex >= 4}" style="width: 350px;">
                          {{ item.name }}
                        </td>
                        
                        <td v-for="(value, colIndex) in item.values" :key="colIndex"
                          :class="{
                            'font-weight-bold': rowIndex === 1,
                          }"
                          class="text-center" 
                        >
                          <v-text-field
                            v-if="[0].includes(rowIndex) && [0].includes(colIndex)"
                            v-model="item.values[colIndex]"
                            :disabled="false"
                            dense
                            class="mb-n4 centered-input"
                            style="text-align: center;"
                            @input="updateItemsHedgingState(colIndex, itemsHedgingState[colIndex].values)"
                          >
                          </v-text-field>
                          <template v-else>
                            {{ value }}
                          </template>
                        </td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </div>
              </v-card>
            </v-col>

            <v-col
              xs="0"
              sm="0"
              md="0"
              lg="1"
              xl="1"
            >
            </v-col>
          </v-row>
        </v-sheet>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState, mapWritableState, mapActions } from "pinia"
import { useTableStore } from "./../store/index.js"

export default {
  data () {
    return {
      itemsExposicaoCambial: [
        { name: "Fluxo de Caixa (dc)", values: ["0", "15", "30", "45", "60"] },
        { name: "Contas a Receber", values: ["", "", "80", "100", ""] },
        { name: "Contas a Pagar", values: ["", "", "-100", "", ""] },
        { name: "Exposição líquida", values: ["0", "0", "-20", "100", "0"] },
        { name: "Total Exposição", values: ["120"] },
      ],
      itemsAnaliseDeRisco: [
        { values: ["Sentido (fluxo)", "A pagar", "A receber"] },
        { values: ["Prazo (fluxo)", "30 dc", "45 dc"] },
        { values: ["Volume", "-20", "100"] },
        { values: ["Sentido Risco", "Alta (var.cambial)", "Baixa (var.cambial)"] },
      ],
      itemsFluxo: [
        { name: "Total Exposição Líquida", values: ["200"] },
        { name: "Var (total exposição)", values: ["2.1"] },
      ],
      itemsHedging: [
        { name: "Fator de hedge máximo (%)", values: ["70", "", "", "", ""] },
        { name: "Hedge - venda NDF R$/USD", values: ["", "", "", "-70", ""] },
        { name: "Hedge - compra NDF R$/USD", values: ["", "", "14", "", ""] },
        { name: "Hedge prazo (dc)", values: ["0", "15", "30", "45", "60"] },
        { name: "Exposição líquida (após derivativos)", values: ["0", "0", "-6", "30", "0"] },
        { name: "Total Hedge em derivativos", values: ["84"] },
        { name: "Total Exposição Líquida (após derivativos)", values: ["36"] },
        { name: "VaR (Total exposição líquida, após derivativos)", values: ["0.5"] },
      ],
    }
  },
  computed: {
    ...mapWritableState(useTableStore, {
      itemsExposicaoCambialState: "itemsExposicaoCambial",
      itemsAnaliseDeRiscoState: "itemsAnaliseDeRisco",
      itemsFluxoState: "itemsFluxo",
      itemsHedgingState: "itemsHedging",
    }),
    ...mapState(useTableStore, [
      "isExposicaoCambialModified",
      "isAnaliseDeRiscoModified",
      "isFluxoModified",
      "isHedgingModified",
    ]),
  },
  mounted () {
  },
  methods: {
    ...mapActions(useTableStore, {
      setItemsExposicaoCambialState: "setItemsExposicaoCambial",
      setItemsAnaliseDeRiscoState: "setItemsAnaliseDeRisco",
      setItemsFluxoState: "setItemsFluxo",
      setItemsHedgingState: "setItemsHedging"
    }),
    updateItemsExposicaoCambialState(index, value) {
      this.itemsExposicaoCambialState[index].values = value
    },
    updateItemsAnaliseDeRiscoState(index, value) {
      this.itemsAnaliseDeRiscoState[index].values = value
    },
    updateItemsFluxoState(index, value) {
      this.itemsFluxoState[index].values = value
    },
    updateItemsHedgingState(index, value) {
      this.itemsHedgingState[index].values = value
    },
    saveChangesExposicaoCambial() {
      this.setItemsExposicaoCambialState()
    },
    saveChangesAnaliseDeRisco() {
      this.setItemsAnaliseDeRiscoState()
    },
    saveChangesFluxo() {
      this.setItemsFluxoState()
    },
    saveChangesHedging() {
      this.setItemsHedgingState()
    }
  }
}
</script>

<style scoped>
.exclusive-cell {
  background-color: #424242 !important;
}
.centered-input >>> input {
  text-align: center
}
.no-hover tbody tr:hover:not(.exclusive-cell) {
  background-color: unset !important;
}
</style>